/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Suspense,lazy } from 'react';
import {config}  from 'config';
import {PrivateRoute} from 'authorization/_components';
import LoadingScreen from 'comp/LoadingScreen';
import { LanguageProvider } from 'contexts/LanguageContext';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const RouteWithLayout = Loadable(lazy(() => import('../common/RouteWithLayout' /* webpackChunkName: "RouteWithLayout" */)));

const NotFound = Loadable(lazy(() => import('pages/NotFound' /* webpackChunkName: "NotFound" */)));
const ServerError = Loadable(lazy(() => import('pages/ServerError' /* webpackChunkName: "ServerError" */)));
const Login = Loadable(lazy(() => import('pages/authentication/Login' /* webpackChunkName: "Login" */)));
const Docs = Loadable(lazy(() => import('pages/Docs' /* webpackChunkName: "Docs" */)));
const AdminCars = Loadable(lazy(() => import('view/AdminCars' /* webpackChunkName: "AdminCars" */)));
const AdminDrivers = Loadable(lazy(() => import('view/AdminDrivers' /* webpackChunkName: "AdminDrivers" */)));
const AddDriver = Loadable(lazy(() => import('view/AddDriver' /* webpackChunkName: "AdminDrivers" */)));
const EditDriver = Loadable(lazy(() => import('view/EditDriver' /* webpackChunkName: "EditDriver" */)));
const RegistrationDriver = Loadable(lazy(() => import('view/RegistrationDriver' /* webpackChunkName: "RegistrationDriver" */)));
const AdminAPI = Loadable(lazy(() => import('view/AdminAPI' /* webpackChunkName: "AdminAPI" */)));
const AddAPI = Loadable(lazy(() => import('view/AddAPI' /* webpackChunkName: "AddAPI" */)));
const EditAPI = Loadable(lazy(() => import('view/EditAPI' /* webpackChunkName: "EditAPI" */)));
const SocialProfile = Loadable(lazy(() => import('../views/SocialProfile' /* webpackChunkName: "SocialProfile" */)));
const AddCar = Loadable(lazy(() => import('view/AddCar' /* webpackChunkName: "AddCar" */)));
const EditCar = Loadable(lazy(() => import('view/EditCar' /* webpackChunkName: "EditCar" */)));
const Taxon4ek = Loadable(lazy(() => import('view/Taxon4ek' /* webpackChunkName: "Taxon4ek" */)));

const AdminLayout = Loadable(lazy(() => import('../layouts/AdminLayout' /* webpackChunkName: AdminLayout" */)));
const MinimalLayout = Loadable(lazy(() => import('../layouts/Minimal' /* webpackChunkName: "Minimal" */)));
const MainSiteLayout = Loadable(lazy(() => import('../layouts/MainSite' /* webpackChunkName: "MainSite" */)));
const Taxon4ekLayout = Loadable(lazy(() => import('../layouts/Taxon4ekLayout' /* webpackChunkName: "Taxon4ekLayout" */)));

const GuestGuard = Loadable(lazy(() => import('comp/GuestGuard' /* webpackChunkName: "GuestGuard" */)));
const AuthGuard = Loadable(lazy(() => import('comp/AuthGuard' /* webpackChunkName: "AuthGuard" */)));

const DocsView = Loadable(lazy(() => import('../views/Docs' /* webpackChunkName: "Docs" */)));

const routes = [

        {
          path: `${config.baseUrl}/`,
          exact: true,
          component: Loadable(() =>  <RouteWithLayout component={(props) => <Taxon4ek/>}
          exact
          layout={Taxon4ekLayout}
          path= {`${config.baseUrl}/`}/>)
        },
        {
          path: `${config.baseUrl}/taxon4ek`,
          exact: true,
          component: Loadable(() =>  <RouteWithLayout component={(props) => <Taxon4ek/>}
          exact
          layout={Taxon4ekLayout}
          path= {`${config.baseUrl}/taxon4ek`}/>)
        },
        {
          path: `${config.baseUrl}/admin/login`,
          exact: true,
          component: () =>
          <GuestGuard>
          <LanguageProvider>
          <Login />
          </LanguageProvider>
          </GuestGuard>
        },
        {
          path: `${config.baseUrl}/statement-policy-personal-data`,
          exact: true,
          component: () => <RouteWithLayout component={()=><DocsView type="statementPolicyPersonalData" />}
        exact
        layout={MainSiteLayout}
        path= {`${config.baseUrl}/statement-policy-personal-data`}/>
        },
        {
          path: `${config.baseUrl}/user-agreement`,
          exact: true,
          component: () => <RouteWithLayout component={()=><DocsView type="userAgreement" />}
        exact
        layout={MainSiteLayout}
        path= {`${config.baseUrl}/user-agreement`}/>
        },
{
  route: '/admin',
  component: AdminLayout,
  routes: [
    {
      path: `${config.baseUrl}/admin/profile`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/profile`} component={SocialProfile} />
    },
    {
      path: `${config.baseUrl}/admin/cars`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/cars`} component={AdminCars} />
    },
    {
      path: `${config.baseUrl}/admin/cars/add`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/cars/add`} component={AddCar} />
    },
    {
      path: `${config.baseUrl}/admin/car/:id/edit/:tab`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/car/:id/edit/:tab`} component={EditCar} />
    },
    {
      path: `${config.baseUrl}/admin/drivers`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/drivers`} component={AdminDrivers} />
    },
    {
      path: `${config.baseUrl}/admin/drivers/add`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/drivers/add`} component={AddDriver} />
    },
    {
      path: `${config.baseUrl}/admin/driver/:id/edit/:tab`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/driver/:id/edit/:tab`} component={EditDriver} />
    },
    {
      path: `${config.baseUrl}/admin/driver/registration`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/driver/registration`} component={RegistrationDriver} />
    },
    {
      path: `${config.baseUrl}/admin/api`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/api`} component={AdminAPI} />
    },
    {
      path: `${config.baseUrl}/admin/api/add`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/api/add`} component={AddAPI} />
    },
    {
      path: `${config.baseUrl}/admin/api/:id/edit/:tab`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/admin/api/:id/edit/:tab`} component={EditAPI} />
    },
    {
      path: `${config.baseUrl}/instruction-eis`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/instruction-eis`} component={()=><DocsView type="instructionEIS" />} />
    },
    {
      path: `${config.baseUrl}/not-found`,
      exact: true,
      component: () =>  <NotFound />
    },
    {
      path: `${config.baseUrl}/500`,
      exact: true,
      component: () => <ServerError />
    },
    {
      component: () =>
      <AuthGuard>
      <LanguageProvider>
      <NotFound />
      </LanguageProvider>
      </AuthGuard>
    }
  ]
},
{
  path: `${config.baseUrl}/not-found`,
  exact: true,
  component: () =>  <NotFound />
},
{
  path: `${config.baseUrl}/500`,
  exact: true,
  component: () => <ServerError />
},
];

export default routes;
